import { useState, useEffect } from 'react';
import Parse from '../config/ParseConfig';

export const useTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchTransactions = async () => {
    try {
      const Transaction = Parse.Object.extend('Transaction');
      const query = new Parse.Query(Transaction);
      
      // Add any query constraints
      query.descending('timestamp');
      query.limit(100); // Adjust limit as needed
      
      const results = await query.find();
      
      // Convert Parse objects to plain objects
      const plainResults = results.map(transaction => ({
        id: transaction.id,
        contributor: transaction.get('contributor'),
        tokenType: transaction.get('tokenType'),
        txHash: transaction.get('txHash'),
        timestamp: transaction.get('timestamp'),
        amountInUSD: transaction.get('amountInUSD'),
        tokenPrice: transaction.get('tokenPrice'),
        bonusPercentage: transaction.get('bonusPercentage'),
        baseTokens: transaction.get('baseTokens'),
        bonusTokens: transaction.get('bonusTokens'),
        tokenAwarded: transaction.get('tokenAwarded')
      }));

      setTransactions(plainResults);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  return { transactions, loading, error, refetch: fetchTransactions };
};